import { toast } from 'react-toastify';
import { userConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions } from './';
//import { history } from '../_helpers';
export const userActions = {
    login,
    logout,
    marketAnalysisDashboard,
    childListActiveUser,
    activeSport,
    uploadImage,
    uploadMultiImages,
    getAppSettingList,
    updateAppSetting,
    createAppSetting,
    deleteAppSetting,
    disableAppSetting,
};

function login(data, props) {
    console.log("login___props::", props);

    return dispatch => {
        dispatch(request({ data }));
        userService.login(data)
            .then(
                user => {
                    dispatch(success(user));

                    console.log("useruseruser09122022:", user);

                    dispatch(alertActions.success("Login Successfully."));
                    props.history.push(`/app/dashboard`)
                    // history.push({ pathname: '#/app/mainpage' });
                    // window.location.reload();
                    console.log("props.history  ",props.history);
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_FIRST_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_FIRST_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FIRST_FAILURE, error } }
}

function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}
function createAppSetting(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        userService.createAppSetting(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Setting Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.getAppSettingList(reqData));
                    // dispatch(this.uploadImageClear());


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.CREATE_APP_SETTING_REQUEST } }
    function success(users) { return { type: userConstants.CREATE_APP_SETTING_SUCCESS, users } }
    function failure(error) { return { type: userConstants.CREATE_APP_SETTING_FAILURE, error } }
}
function getAppSettingList(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        userService.getAppSettingList(data)
            .then(
                users => {
                    // console.log("getAppSettingList $$$$ action:", users);
                    dispatch(success(users));
                    // dispatch(this.uploadImageClear());
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_APP_SETTING_LIST_REQUEST } }
    function success(users) { return { type: userConstants.GET_APP_SETTING_LIST_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_APP_SETTING_LIST_FAILURE, error } }
}
function updateAppSetting(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        userService.updateAppSetting(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Setting Update Successfully."));
                    dispatch(success(users));
                    dispatch(this.getAppSettingList(reqData));
                    // dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.UPDATE_APP_SETTING_REQUEST } }
    function success(users) { return { type: userConstants.UPDATE_APP_SETTING_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPDATE_APP_SETTING_FAILURE, error } }
}
function deleteAppSetting(data, paginationData) {
    // let reqData = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        userService.deleteAppSetting(data)
            .then(
                users => {
                    dispatch(alertActions.success("Setting Delete Successfully."));
                    dispatch(success(users));
                    dispatch(this.getAppSettingList(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.DELETE_APP_SETTING_REQUEST } }
    function success(users) { return { type: userConstants.DELETE_APP_SETTING_SUCCESS, users } }
    function failure(error) { return { type: userConstants.DELETE_APP_SETTING_FAILURE, error } }
}
function disableAppSetting(data, paginationData) {

    return dispatch => {
        dispatch(request());
        userService.disableAppSetting(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(alertActions.success("Status Update Successfully."));
                    dispatch(this.getAppSettingList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
                );
            };
    function request() { return { type: userConstants.DISABLE_APP_SETTING_REQUEST } }
    function success(users) { return { type: userConstants.DISABLE_APP_SETTING_SUCCESS, users } }
    function failure(error) { return { type: userConstants.DISABLE_APP_SETTING_FAILURE, error } }
}
function marketAnalysisDashboard(data) {
    return dispatch => {
        dispatch(request());
        userService.marketAnalysisDashboard(data)
            .then(
                users => {
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.MARKET_ANALYSIS_DASHBORD_REQUEST } }
    function success(users) { return { type: userConstants.MARKET_ANALYSIS_DASHBORD_SUCCESS, users } }
    function failure(error) { return { type: userConstants.MARKET_ANALYSIS_DASHBORD_FAILURE, error } }
}


function uploadImage(data){
    return dispatch => {
        dispatch(request());
        userService.uploadImage(data)
        .then(
            uploadImage => {
                toast("Image Uploaded Successfully");
                dispatch(success(uploadImage));
            },
            error =>{
                dispatch(alertActions.error(error));
                dispatch(failure(error))
            }

        )
    };
    function request(){
        return{
            type:userConstants.FILE_UPLOAD_STATUS_REQUEST
        }
    }
    function success(uploadImage) {
        return {
            type:userConstants.FILE_UPLOAD_STATUS_SUCCESS , uploadImage
        }
    }
    function failure(error) {
        return {
            type: userConstants.FILE_UPLOAD_STATUS_FAILURE, error
        }
    }
}

function uploadMultiImages(data){
    console.log('datadatadatadatadataaaaaaaaaaaaaaaa', data)
    return dispatch => {
        dispatch(request());
        userService.uploadMultiImages(data)
        .then(
            users => {
                toast("Image Uploaded Successfully");
                dispatch(success(users));
            },
            error =>{
                dispatch(alertActions.error(error));
                dispatch(failure(error))
            }

        )
    };
    function request(){
        return{
            type:userConstants.FILE_UPLOAD_MULTI_STATUS_REQUEST
        }
    }
    function success(users) {
        return {
            type:userConstants.FILE_UPLOAD_MULTI_STATUS_SUCCESS , users
        }
    }
    function failure(error) {
        return {
            type: userConstants.FILE_UPLOAD_MULTI_STATUS_FAILURE, error
        }
    }
}

function childListActiveUser(data) {
    return dispatch => {
        dispatch(request());
        userService.childListActiveUser(data)
            .then(
                users => {
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.CHILD_LIST_ACTIVE_USER_REQUEST } }
    function success(users) { return { type: userConstants.CHILD_LIST_ACTIVE_USER_SUCCESS, users } }
    function failure(error) { return { type: userConstants.CHILD_LIST_ACTIVE_USER_FAILURE, error } }
}

function activeSport() {
    return dispatch => {
        dispatch(request());
        userService.activeSport()
            .then(
                users => {
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.ACTIVE_SPORT_REQUEST } }
    function success(users) { return { type: userConstants.ACTIVE_SPORT_SUCCESS, users } }
    function failure(error) { return { type: userConstants.ACTIVE_SPORT_FAILURE, error } }
}
